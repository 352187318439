.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-btn {
  position: absolute;
  margin-top: -70px;
  margin-left: 900px;
}

@media (max-width: 768px) {
  .about-btn {
    padding: 0 20px;
  }
  .text-lg-center {
    text-align: center;
  }
}

.pdf-upload {
  padding: 0.5rem 1.2rem !important;
}

.accordion-item {
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.accordion-title {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  line-height: 17px;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #bb3627;
}

.accordion-content {
  padding: 10px;
}

.text-font {
  font-size: 13px;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-color {
  width: 15px;
  height: 15px;
  appearance: none; 
  border: 1px solid #bb3627;
  border-radius: 4px; 
}

.custom-color:checked {
  background-color: #bb3627; 
  border-color: #bb3627; 
  color: #fff; 
}

.custom-color:checked::before {  
  position: absolute;
  content: "✔"; 
  display: block;
  text-align: center;
  color: #fff;
  font-size: 9px; 
  margin-left: 3px;
}

.filter-title {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  line-height: 17px;
  margin-bottom: 12px;
  color: #bb3627;
}

.send-inquiry{
  margin-left: 32px;
}

@media(max-width:575.98px){
  .send-inquiry{
    margin-left: 0px;
  }

  .disable-pointer-events {
    pointer-events: none;
}
.lightbox-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}
}

.testimonial-body {
  background-color: #fee8e8;
  border-radius: 20px;
  padding : 32px;
  width: auto;
  margin-bottom:35px;   
}

.active-link {
  color:#bb3627 !important;  
}


/* instagram css */
.instagram-gallery {
  padding: 40px 0;
  background-color: #f9f9f9;
}

.gallery-item {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
}

.media-container:hover .gallery-image {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.media-container:hover .overlay {
  opacity: 1;
}

.overlay i {
  color: white;
  font-size: 2.5rem;
}
