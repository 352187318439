body {
  margin: 0;
}

.category-image {
  display: none;
}

.dropdown-link:hover .category-image {
  display: block;
}

.mobile-price {
  background-color: #bb3627;
}

.wrap {
  white-space: nowrap;
}

.rounded-square {
  background-color: #b490b0;
  border-radius: 8px;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 1px solid black;
}

.color-widget .item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

textarea#exampleFormControlTextarea1 {
  height: 120px;
}

td.dataTables_empty {
  display: none;
}

.rotate-ring-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.rotate-ring {
  position: relative;
  display: inline-block;
  padding: 10px;
}

.rotate-ring img {
  width: 60px;
  height: 60px;
}

.rotate-ring::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 4px solid transparent;
  border-top-color: #bb3627;
  border-radius: 50%;
  animation: rotate-ring 1s linear infinite;
}

@keyframes rotate-ring {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a#bookApp {
  position: fixed;
  bottom: 320px;
  right: -164px;
  transition: right 0.3s ease;
}

a#whatsapp-app {
  position: fixed;
  bottom: 373px;
  right: -131px;
  transition: right 0.3s ease;
}

@media (max-width: 975px) {
  a#bookApp {
    position: fixed;
    bottom: 150px;
    right: -164px;
    transition: right 0.3s ease;
  }

  a#whatsapp-app {
    position: fixed;
    bottom: 201px;
    right: -131px;
    transition: right 0.3s ease;
  }

  a#bookApp:hover {
    right: 0;
  }
  a#whatsapp-app:hover {
    right: 0;
  }
  .mobile-view {
    margin-top: 6%;
  }
}

a#bookApp:hover {
  right: 0;
}

a#whatsapp-app:hover {
  right: 0;
}

i.fab.fa-whatsapp.mr-2.fs-20.whatsapp-icon {
  color: #43df1ee0;
  font-weight: 600;
}

.gold-rate {
  background-color: #bb3627;
  color: #fff;
  border-radius: 2px;
  border: 1px solid #bb3627;
  font-weight: 600 !important;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 13px;
  width: 195px;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

@media (max-width: 1200px) {
  .gold-rate {
    padding: 6px;
    font-size: 10px;
  }
}

.border-buttom {
  border-bottom: 1px solid #e8d0c1 !important;
}

.hover-name:hover {
  color: #bb3627;
}

.active1 {
  box-shadow: 0 0 4px #bc3628;
}

.row.animate-products .col-6 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.row.animate-products .col-6:nth-child(odd) {
  animation: fadeInUp 0.5s forwards 0.5s;
}

.row.animate-products .col-6:nth-child(even) {
  animation: fadeInUp 0.5s forwards 1s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.search-field {
  background-color: transparent;
  background-image: url(https://wp-themes.com/wp-content/themes/twentythirteen/images/search-icon.png);
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  cursor: pointer;
  height: 40px;
  margin: 3px 0;
  padding: 0 0 0 34px;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition: width 400ms ease, background 400ms ease;
  width: 0px;
  cursor: pointer;
}
@media (max-width: 1400px) {
  .search-field:focus {
    width: 100px !important;
  }
}
.search-field:focus {
  background-color: #fff;
  border-bottom: 1px solid #bb3627;
  cursor: pointer;
  outline: 0;
  width: 175px;
  color: #000;
}
.search-form .search-submit {
  display: none;
}
.brand-logo-home {
  width: 88%;
  border-radius: 10px;
}
.search-nav {
  margin-right: 5px;
  margin-top: 7px;
}
.grid-container {
  display: grid;
  grid-template-columns: 4fr 3fr;
}
.barnd-menu-logo {
  height: 120px;
}
.brand-product-img {
  width: 300px;
  height: 250px;
}
@media (max-width: 1300px) {
  .grid-container {
    grid-template-columns: 3fr 3fr;
  }

  .barnd-menu-logo {
    height: 100px;
  }

  .brand-product-img {
    width: 300px;
    height: 180px;
  }
}
ul.dropdown-menu.pt-3.pb-0.pb-xl-3.x-animated.x-fadeInUp.show {
  display: flex !important;
  flex-wrap: wrap;
  top: 81px;
  width: 600px;
  left: auto;
  right: auto;
}

.menu-space {
  padding: 0px 0px 4px;
}
img.brand-nav-img {
  max-width: 160px !important;
  height: 94px !important;
  border-radius: 10px;
}
.radius-logo {
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #5b5b5b;
  opacity: 1;
  border-radius: 0;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #ccc;
}
.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-card {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}
.client-logo-02 .item:hover {
  border: 1px solid #bb3627;
}
.inner-box-collection:hover {
  border: 1px solid #bb3627;
}
img.nav-icon {
  width: 45px;
}
.error-size {
  font-size: 9.5rem;
  color: #bb3627;
}
.out-of-stock {
  position: absolute;
  top: 50%;
  width: 100%;
  border: 1px solid #bba7a7;
  border-left: none;
  border-right: none;
  color: #fff;
  background: #000;
  text-transform: uppercase;
}
.hover-image-home {
  width: 100%;
  height: 130px;
}
div.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 0px;
  line-height: 25px;
  height: 35px;
  width: 100%;
  outline: none;
}
p.sk-ig-profile-usename {
  text-align: start !important;
}
.gettestimonials {
  border-radius: 50%;
  width: 84px;
  height: 84px;
}
.hover-image-home {
  width: 100%;
  height: 160px;
}

@media (min-width: 992px) {
  .fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(230px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.item-title {
  padding: 11px 0px 5px 0px;
  border: 1px solid #e8d0c1;
  border-top: none;
}
.hover-name:hover {
  color: #bb3627;
}
.underline-warning {
  text-decoration-color: 1px solid #e8d0c1 !important; 
}
.btn-envelope {
  display: block;
  border: 1px solid #bc3628;
  padding: 10px 30px;
  width: 100%;
  background: none;
}
.btn-envelope i {
  color: #bc3628;
  vertical-align: middle;
  margin-right: 1px;
  margin-bottom: 4px;
  padding: 0px;
  margin-top: 0px;
  font-weight: 700;
  font-size: 24px;
}
.btn-whatsapp {
  display: block;
  border: 1px solid #bc3628;
  padding: 10px 30px;
  width: 100%;
  background: none;
}
.btn-whatsapp i {
  color: #4caf50;
  vertical-align: middle;
  margin-right: 1px;
  margin-bottom: 4px;
  padding: 0px;
  margin-top: 0px;
  font-weight: 700;
  font-size: 24px;
}
.rotate-ring-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.rotate-ring {
  position: relative;
  display: inline-block;
  padding: 10px;
}
.rotate-ring img {
  width: 60px;
  height: 60px;
}
.rotate-ring::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 4px solid transparent;
  border-top-color: #bb3627;
  border-radius: 50%;
  animation: rotate-ring 1s linear infinite;
}
@keyframes rotate-ring {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.brand-logo-img {
  width: 200px;
  margin-bottom: 17px;
}
.active1 {
  box-shadow: 0 0 4px #bc3628;
}
.sticky-area {
  height: 98px;
}
@media only screen and (max-width: 600px) {
  .sticky-area {
    height: 123px;
  }
}
.dropdown-item {
  cursor: pointer;
}
.slick-prev:before,
.slick-next:before {
  color: #bb3627 !important;
}
@media screen and (min-width: 768px) and (max-width: 768px) and (min-height: 185px) and (max-height: 185px) {
  .sticky-area {
    height: 140px !important;
  }
}
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .sticky-area {
    height: 140px !important;
  }
}
.scroll-to-top {
  background-color: white;
  color: black;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}
.scroll-to-top:hover {
  background-color: black;
  color: white;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.pre-loader{
	background: #ffffff;
	background-position: center center;
	background-size: 13%;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 12345;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.pre-loader .loader-logo{
	padding-bottom: 15px;
}
.pre-loader .loader-progress{
	height: 8px;
	border-radius: 15px;
	max-width: 200px;
	margin: 0 auto;
	display: block;
	background: #ecf0f4;
	overflow: hidden;
}
.pre-loader .bar{
	width: 0%;
	height: 8px;
	display: block;
	background: #1b00ff;
}
.pre-loader .percent{
	text-align: center;
	font-size: 24px;
	display: none;
}
.pre-loader .loading-text{
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	padding-top: 15px;
}
.banner-text h1 {
  color: #bb3627;
  margin-bottom: 0;
}

.brand-logos-nav {
    display: flex;
    flex-direction: column;
    width: auto;
}

.tag {
  /* border-radius: 20px; */
  padding: 5px 15px;
  font-size: 14px;
  background-color:  #e8d0c1;
  border: 1px solid #e8d0c1;
  margin: 5px;
  display: inline-block;
}

.popup-text{
  color: #bb3627;
}